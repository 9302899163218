import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../assets/logo-abak.png';
import { Link } from 'react-router-dom';
import styled from "@emotion/styled";
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-shadow: #199CB5 0px 0 8px;
    color: #199CB5 !important;
  }
  
  &.active {
    text-shadow: #199CB5 0px 0 8px;
    color: #199CB5 !important;
  }
`;

const drawerWidth = '100%';
const navItems = [
  {
    name: 'O FIRMIE',
    href: '/about-us',
  },
  {
    name: 'OFERTA',
    href: '/offer',
  },
  {
    name: 'NASZE REALIZACJE',
    href: '/our-projects',
  },
  {
    name: 'REFERENCJE',
    href: '/references',
  },
  {
    name: 'KONTAKT',
    href: '/contact',
  }
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '23px 0'
        }}
      >
        <Link to="/">
          <img src={logo} alt="Logo" style={{ height: 58 }} />
        </Link>
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ position: 'absolute', right: 10, color: 'black', top: 25 }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box onClick={handleDrawerToggle}>
        <List sx={{ marginTop: '50px' }}>
          {navItems.map((item) => (
            <ListItem key={item.name} disablePadding>
              <Link to={item.href} style={{ color: 'inherit', textDecoration: 'none', width: '100%', textAlign: 'left', marginLeft: 45 }}>
                <ListItemText primary={item.name} />
                <Divider />
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', height: 112 }}>
      <CssBaseline />
      <AppBar component="nav" sx={{
        height: 112,
        background: 'linear-gradient(89.51deg, rgba(199, 221, 226, 0.95) 3.8%, rgba(60, 63, 65, 0.830658) 30.82%, rgba(23, 26, 29, 0.95) 56.06%, rgba(23, 26, 29, 0.95) 100.63%)',
        boxShadow: 'unset'
      }}>
        <Toolbar sx={{ height: 112 }}>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Link to="/">
              <img src={logo} alt="Logo" style={{ height: 58 }} />
            </Link>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginLeft: 'auto', display: { sm: 'none' }, color: '#199CB5' }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to="/">
              <img src={logo} alt="Logo" style={{ display: { xs: 'none', md: 'flex' }, marginRight: 1, height: 58 }} />
            </Link>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: 'auto'}}>
            {navItems.map((item) => (
                <NavLink className={location.pathname.includes(item.href) ? 'active' : ''} key={item.name} to={item.href}
                         style={{color: '#fff', textDecoration: 'none', margin: '0 10px'}}>
                  {item.name}
                </NavLink>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              // background: 'linear-gradient(180deg, rgb(255 255 255 / 79%) 5%, rgb(21 25 28 / 83%) 12%)',
              background: 'linear-gradient(rgba(255, 255, 255, 0.79) 11%, rgba(21, 25, 28, 0.83) 15%)',
              color: 'white'
            }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;