import styled from '@emotion/styled';
import backgroundImage from '../assets/contact.png';
import { Link } from 'react-scroll';
import downArrowIcon from '../assets/icons/icon-arrow-down.svg';
import React, {useEffect, useState} from "react";
import iconAddr from '../assets/icons/icon-addr-trans.svg';
import iconPhone from '../assets/icons/icon-phone-trans.svg';
import iconEmail from '../assets/icons/icon-email-trans.svg';


const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  margin-top: -112px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(269.9deg, rgba(0, 0, 0, 0) 0.06%, rgba(0, 0, 0, 0.8) 68.94%);
  z-index: 1;
  @media (max-width: 600px) {
    background: linear-gradient(269.9deg, rgba(0, 0, 0, 0) 0.06%, rgba(0, 0, 0, 0.8) 0%);
  }
`;

const Text = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 335px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 180px;
    left: 10px;
  }

  @media (max-width: 360px) {
    font-size: 36px;
    bottom: 165px;
    left: 10px;
  }
`;

const Text2 = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 250px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 60px;
    left: 10px;
  }

  @media (max-width: 360px) {
    font-size: 36px;
  }
`;

const Separator = styled.div`
  height: 96px;
  background: linear-gradient(180deg, #000000 0%, rgba(21, 24, 27, 0) 31.82%);
`;

const HighlightedText = styled.span`
  color: #9ECFD9;
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 20px 1px #199CB5;
  height: 466px;
  
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
  }
`

const ContactInfo = styled.div`
  width: 45%;
  background: linear-gradient(180deg, #11778A 0%, #199CB5 100%);
  padding-top: 100px;

  @media (max-width: 600px) {
    width: 100%;
    height: 400px;
  }
`

const Map = styled.div`
  width: 55%;

  @media (max-width: 600px) {
    width: 100%;
  }


  .map-container {
    position: relative;
    overflow: hidden;
    
    // padding-bottom: ${props => 181.8 * 466/props.aspectRatio}%;
    padding-bottom: ${props => 100 * 466/props.mapWidth}%;
    
    @media (max-width: 600px) {
      padding-bottom: 100%;
    }
    

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const InfoRow = styled.div`
  display: flex;
  //justify-content: center;
  //align-items: center;
  padding-left: 10%;
  margin-bottom: 20px; // Add some space between the rows
  width: 100%;
`;

const InfoIcon = styled.img`
  margin-right: 30px;
  
  rect {
    fill: transparent;
  }
`;

const InfoContentContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

const Content = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: left;
`

const ArrowContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 49%;
  z-index: 10;
  background: transparent;
  
  @media (max-width: 600px) {
    right: 45%;
  }
`



const Contact = () => {
  const [mapWidth, setMapWidth] = useState(0);
  useEffect(() => {
    const calculateMapWidth = () => {
      setMapWidth(window.innerWidth * 0.55);
    };

    // Calculate the aspect ratio initially
    calculateMapWidth();

    // Recalculate the aspect ratio whenever the window is resized
    window.addEventListener('resize', calculateMapWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', calculateMapWidth);
    };
  }, []);

  return (
    <>
      <Background>
        <Gradient/>
        <Text>
          ABAK <HighlightedText>SYSTEM</HighlightedText>
        </Text>
        <Text2>
          JESTEŚMY TU, <HighlightedText>ABY CI POMÓC</HighlightedText>
        </Text2>
        <Link to="nextSection" smooth={true} duration={500} offset={-96} >
          <ArrowContainer>
            <img src={downArrowIcon} alt="Down arrow" />
          </ArrowContainer>
        </Link>
      </Background>
      <Separator id="nextSection" />
      <ContactSection>
        <ContactInfo>
          <InfoRow>
            <InfoIcon src={iconAddr} alt="Icon 1" />
            <InfoContentContainer>
              <Content>ABAK SYSTEM SP. Z O.O.</Content>
              <Content>Starorudzka 10/12</Content>
              <Content>93-418 Łódź</Content>
              <Content>NIP: 729 268 95 02</Content>
            </InfoContentContainer>
          </InfoRow>
          <InfoRow>
            <InfoIcon src={iconPhone} alt="Icon 2" />
            <InfoContentContainer>
              <Content>(42) 640 20 50</Content>
              <Content>601 25 70 80</Content>
            </InfoContentContainer>
          </InfoRow>
          <InfoRow>
            <InfoIcon src={iconEmail} alt="Icon 3" />
            <Content style={{ marginTop: 1.5 }}>abak@post.pl</Content>
          </InfoRow>
        </ContactInfo>
        <Map mapWidth={mapWidth}>
          <div className="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2472.0234944453473!2d19.434726576620847!3d51.71431269556659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a345aa4e83f9f%3A0x22a1d9825a23354d!2sAbak%20System.%20Pyra%20P.!5e0!3m2!1spl!2spl!4v1721062181189!5m2!1spl!2spl" width="400" height="422" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Map>
      </ContactSection>
    </>

  );
}

export default Contact;