import React, {useState} from 'react';
import styled from '@emotion/styled';
import Modal from 'react-modal';
import SearchIcon from '../../assets/icons/icon-search.svg';
import {useMediaQuery} from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  padding: 42px;
`;

const GridItem = styled.div`
  position: relative;
  width: 211px; // Set a width
  height: 300px;

  &:hover {
    button {
      visibility: visible;
    }

    div {
      background: linear-gradient(265deg, rgba(25, 156, 181, 0.713344712885154) 30%, rgba(174, 242, 255, 0.7497592787114846) 50%, rgba(25, 156, 181, 0.7245491946778712) 75%),
      ${props => props.src ? `url(${props.src})` : '#199CB5'};
      background-size: cover;
    }
  }
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 300px;
  background: ${props => props.src ? `url(${props.src})` : '#199CB5'};
  background-size: cover;
  transition: background 0.3s ease-in-out; // Add this line to animate the background change
`;


const Button = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  background: none; // Add this if you want to remove the default button background
  border: none; // Add this if you want to remove the default button border
`;


const PdfGrid = ({pdfs}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const openModal = (pdf) => {
    setSelectedPdf(pdf);
    setModalIsOpen(true);
    navigate(`${location.pathname}?modal=true`, { replace: true });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    navigate(location.pathname, { replace: true });
  };

  const isMobile = useMediaQuery('(max-width: 600px)');

  React.useEffect(() => {
    if (!window.location.search.includes('modal=true')) {
      setModalIsOpen(false);
    }
  }, [location]);

  return (
    <Grid>
      {pdfs.map((pdf) => (
        <GridItem key={pdf.id} src={pdf.thumbnail} onClick={() => {
          if (isMobile) {
            window.open(pdf.url, '_blank');
          } else {
            openModal(pdf);
          }
        }}>
          <Thumbnail src={pdf.thumbnail}/>
          <Button>
            <img src={SearchIcon} alt="Preview" style={{width: '40px', height: '40px'}}/>
          </Button>
        </GridItem>
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="PDF Preview"
        style={{
          overlay: {
            zIndex: 1200
          },
          content: {
            padding: '0'
          }
        }}
      >
        {selectedPdf && (
          <object data={selectedPdf.url} type="application/pdf" width="100%" height="100%">
            <p><a href={selectedPdf.url}>Download the PDF</a></p>
          </object>
        )}
      </Modal>
    </Grid>
  );
};

export default PdfGrid;