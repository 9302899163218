import React from 'react';
import styled from '@emotion/styled';
import countersBackground from '../../assets/countersBackground.png'
import CountUp, { useCountUp } from "react-countup";

const OuterDiv = styled.div`
  background-image: linear-gradient(267.26deg, rgba(22, 25, 28, 0.8) 31.64%, rgba(39, 41, 46, 0.8) 95.9%), url(${countersBackground});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 284px;
  color: #199CB5;
  margin-top: 100px;
  margin-bottom: 25px;
  box-shadow: 0px 0px 20px 1px #199CB5;
  
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 42px;
    height: 100%;
    padding: 42px 0;
  }
`;

const Title = styled.h1`
  font-size: 55px;
  font-weight: 700;
  margin: 0;
`;

const Subtitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

const TitleSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 33%;
`;

const Counters = () => {
  useCountUp({
    ref: 'counter',
    end: 1234567,
    enableScrollSpy: true,
    scrollSpyDelay: 500,
  });
  return (
    <OuterDiv>
      <TitleSubtitleWrapper>
        <Title><CountUp end={33} enableScrollSpy /></Title>
        <Subtitle>Lata doświadczenia</Subtitle>
        <span style={{ display: 'none' }} id="counter" />
      </TitleSubtitleWrapper>
      <TitleSubtitleWrapper>
        <Title><CountUp end={85} enableScrollSpy /></Title>
        <Subtitle>Zrealizowanych projektów</Subtitle>
      </TitleSubtitleWrapper>
      <TitleSubtitleWrapper>
        <Title><CountUp end={12750} enableScrollSpy /></Title>
        <Subtitle>Kilometrów zamontowanych przewodów</Subtitle>
      </TitleSubtitleWrapper>
    </OuterDiv>
  );
};

export default Counters;