import styled from '@emotion/styled';
import backgroundImage from '../assets/main-background.jpg';
import ClientsCarousel from "../components/ClientsCarousel";
import JobsCarousel from "../components/JobsCarousel";
import AboutCompany from "../components/AboutCompany";
import Counters from "../components/Counters";
import Offer from "../components/Offer";

const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  margin-top: -112px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(30.1deg, rgba(0, 0, 0, 0.6) 12.8%, rgba(0, 0, 0, 0.48) 27.62%, rgba(0, 0, 0, 0) 85.09%);
  z-index: 1;
`;

const Text = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 135px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;

  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 180px;
    left: 10px;
  }

  @media (max-width: 360px) {
    font-size: 36px;
    bottom: 165px;
    left: 10px;
  }
`;

const Text2 = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 60px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;

  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 60px;
    left: 10px;
  }

  @media (max-width: 360px) {
    font-size: 36px;
  }
`;

const Separator = styled.div`
  height: 96px;
  background: linear-gradient(180deg, #000000 0%, rgba(21, 24, 27, 0) 31.82%);
`;

const HighlightedText = styled.span`
  color: #9ECFD9;
`;


const SectionTitle = styled.div`
  font-family: roboto;
  font-weight: 700;
  font-size: 32px;
  color: #199CB5;
  text-transform: uppercase;
  padding-left: 27px;
  margin-bottom: 50px;
`;

const HomePage = () => {
  return (
    <div>
      <Background>
        <Gradient/>
        <Text>
          ABAK <HighlightedText>SYSTEM</HighlightedText>
        </Text>
        <Text2>
          TWÓJ <HighlightedText>PARTNER W ELEKTRYCE</HighlightedText>
        </Text2>
      </Background>
      <Separator/>
      <div style={{overflowX: 'hidden'}}>
        <SectionTitle>
          Nasi partnerzy i klienci
        </SectionTitle>
        <ClientsCarousel/>
        <AboutCompany/>
        <div style={{height: 50}}/>
        <SectionTitle>
          Oferta
        </SectionTitle>
        <Offer/>
        <div style={{height: 70}}/>
        <SectionTitle>
          Realizacje
        </SectionTitle>
        <JobsCarousel/>
        <Counters/>
      </div>
    </div>

  );
}

export default HomePage;