import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Autoplay, Pagination} from "swiper/modules";

const Carousel = () => {
  const imageNames = Array.from({length: 33}, (_, i) => i + 1);
  const images = imageNames.map((name) => require(`../../assets/clientLogos/${name}.png`));

  return (
    <Swiper
      style={{ margin: '0 20px' }}
      spaceBetween={10}
      pagination={{ clickable: true }}
      modules={[Pagination, Autoplay]}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        200: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        640: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        1300: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
      }}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            padding: '10px',
            marginBottom: '50px',
          }}>
            <img
              style={{
                maxWidth: '228px',
                maxHeight: '100px',
                objectFit: 'contain'
              }}
              src={image}
              alt={`Slide ${index}`}
            />
          </div>
        </SwiperSlide>

      ))}
    </Swiper>
  );
};

export default Carousel;