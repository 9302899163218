import styled from '@emotion/styled';
import backgroundImage from '../assets/aboutBackground.png';
import image1 from '../assets/about/1.png';
import image2 from '../assets/about/2.png';
import image3 from '../assets/about/3.png';
import image4 from '../assets/about/4.png';
import image5 from '../assets/about/5.png';

import { Link } from 'react-scroll';
import downArrowIcon from '../assets/icons/icon-arrow-down.svg';
import React  from "react";
import ClientsCarousel from "../components/ClientsCarousel";


const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  margin-top: -112px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(30.1deg, rgba(0, 0, 0, 0.6) 12.8%, rgba(0, 0, 0, 0.48) 27.62%, rgba(0, 0, 0, 0) 85.09%);
  z-index: 1;
`;

const Text = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 135px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;

  @media (max-width: 1160px) {
    font-size: 48px;
    bottom: 130px;
    left: 10px;
  }
  
  @media (max-width: 850px) {
    font-size: 48px;
    bottom: 200px;
    left: 10px;
  }

  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 180px;
    left: 10px;
  }

  @media (max-width: 380px) {
    font-size: 36px;
    bottom: 165px;
    left: 10px;
  }
`;

const Text2 = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 60px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  
  @media (max-width: 1160px) {
    font-size: 48px;
    bottom: 60px;
    left: 10px;
  }

  @media (max-width: 850px) {
    font-size: 48px;
    bottom: 60px;
    left: 10px;
    width: 500px;
  }

  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 60px;
    left: 10px;
    width: 400px;
    max-width: 95vw;
  }

  @media (max-width: 380px) {
    font-size: 36px;
  }
`;

const Separator = styled.div`
  height: 96px;
  background: linear-gradient(180deg, #000000 0%, rgba(21, 24, 27, 0) 31.82%);
`;

const HighlightedText = styled.span`
  color: #9ECFD9;
`;

const Block = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  height: 500px;
  width: 100vw;
  margin-bottom: 42px;
  box-shadow: 0px -9px 4px rgba(0, 0, 0, 0.23), 0px 9px 4px rgba(0, 0, 0, 0.23);

  &.reverse {
    flex-direction: row-reverse;
  }
  
  @media (max-width: 1000px) {
    height: 600px;
  }

  @media (max-width: 700px) {
    flex-direction: column-reverse;
    height: 100%;

    &.reverse {
      flex-direction: column-reverse;
    }
  }
`

const BluePart = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #11778A 0%, #199CB5 100%);
  padding: 42px;
  justify-content: flex-start;
  width: 50%;
  
  @media (max-width: 700px) {
    width: 100%;
  }
`

const ImagePart = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  width: 50%;
  
  @media (max-width: 700px) {  
    width: 100%;
    height: 400px;
  }
`

const ListItem = styled.li`
  line-height: 40px;
`

const List = styled.ul`
  margin: 0
`

const ArrowContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 49%;
  z-index: 10;
  background: transparent;
  
  @media (max-width: 600px) {
    right: 45%;
  }
`

const SectionTitle = styled.div`
  font-family: roboto;
  font-weight: 700;
  font-size: 32px;
  color: #199CB5;
  text-transform: uppercase;
  padding-left: 27px;
  margin-bottom: 50px;
`;


const Contact = () => {
  return (
    <>
      <Background>
        <Gradient/>
        <Text>
          ABAK <HighlightedText>SYSTEM</HighlightedText>
        </Text>
        <Text2>
          TWOJA <HighlightedText>INSTALACJA,</HighlightedText> NASZE <HighlightedText>DOŚWIADCZENIE</HighlightedText>
        </Text2>
        <Link to="nextSection" smooth={true} duration={500} offset={-96} >
          <ArrowContainer>
            <img src={downArrowIcon} alt="Down arrow" />
          </ArrowContainer>
        </Link>
      </Background>
      <Separator id="nextSection" />
      <SectionTitle>
        Nasi partnerzy i klienci
      </SectionTitle>
      <ClientsCarousel/>
      <div style={{ marginTop: 40 }} />
      <Block>
        <BluePart>
         <p>
           Firma Abak System Sp. z o.o. to doświadczone przedsiębiorstwo z sektora budowlanego, specjalizujące się w wykonawstwie instalacji elektrycznych oraz teletechnicznych.
         </p>
          <p>
            Nasza firma istnieje na rynku od ponad 30 lat, oferując kompleksowe usługi obejmujące projektowanie instalacji elektrycznych, wykonawstwo oraz obsługę serwisową.
          </p>
          <p>
            Nasza oferta obejmuje:
          </p>
          <List style={{ margin: 0 }}>
            <ListItem>projektowanie instalacji elektrycznych</ListItem>
            <ListItem>wykonawstwo instalacji elektrycznych i teletechnicznych</ListItem>
            <ListItem>obsługę serwisową.</ListItem>
          </List>
        </BluePart>
        <ImagePart image={image1} />
      </Block>
      <Block className="reverse">
        <BluePart>
          <p>
            Dzięki wieloletniej obecności na rynku, zdobyliśmy ogromne doświadczenie w sektorze instalacji elektrycznych, realizując dziesiątki przedsięwzięć komercyjnych, przemysłowych oraz infrastrukturalnych na poziomie krajowym i zagranicznym.
          </p>
          <p>
            Nasze usługi obejmują m.in.:
          </p>
          <List style={{ margin: 0 }}>
            <ListItem>instalacje elektryczne dla przedsiębiorstw budowlanych,</ListItem>
            <ListItem>projekty komercyjne, przemysłowe i infrastrukturalne.</ListItem>
          </List>
        </BluePart>
        <ImagePart image={image2} />
      </Block>
      {/*<Block>*/}
      {/*  <BluePart>*/}
      {/*    <p>*/}
      {/*      Na przestrzeni lat, firma Abak System świadczyła usługi dla przedsiębiorstw budowlanych takich jak:*/}
      {/*    </p>*/}
      {/*    <List style={{ margin: 0 }}>*/}
      {/*      <ListItem>Sky tower</ListItem>*/}
      {/*      <ListItem>Manufaktura</ListItem>*/}
      {/*      <ListItem>Atlas arena</ListItem>*/}
      {/*      <ListItem>Skanska</ListItem>*/}
      {/*      <ListItem>Orbis</ListItem>*/}
      {/*      <ListItem>Puro hotel</ListItem>*/}
      {/*      <ListItem>Budimex</ListItem>*/}
      {/*      <ListItem>Złote Tarasy</ListItem>*/}
      {/*    </List>*/}
      {/*  </BluePart>*/}
      {/*  <ImagePart image={image3} />*/}
      {/*</Block>*/}
      <Block>
        <BluePart>
          <p>
            Zatrudniamy wysoko wykwalifikowanych pracowników z szeroką wiedzą i doświadczeniem w obszarze instalacji elektrycznych i teletechnicznych. Regularne szkolenia naszego zespołu pozwalają nam być na bieżąco z najnowszymi technologiami i normami branżowymi, gwarantując klientom usługi na najwyższym poziomie.
          </p>
          <p>
            Dysponujemy własnym sprzętem najwyższej jakości, co pozwala nam dostosować się do zróżnicowanych potrzeb klientów. Nasze regularne serwisy i modernizacje sprzętu zapewniają nie tylko niezawodność, ale także utrzymanie najwyższej efektywności w naszych działaniach.
          </p>
        </BluePart>
        <ImagePart image={image4} />
      </Block>
      <Block className="reverse">
        <BluePart>
          <p>
            Gwarantujemy:
          </p>
          <List style={{ margin: 0 }}>
            <ListItem>profesjonalizm i odpowiedzialność,</ListItem>
            <ListItem>wykorzystanie najlepszych materiałów od renomowanych producentów,</ListItem>
            <ListItem>usługi zgodne z najwyższymi standardami branżowymi.</ListItem>
          </List>
          <p>
            Wierzymy, że nasze zaangażowanie i profesjonalizm umożliwią Państwu skorzystanie z usług, które przekraczają oczekiwania. Skontaktuj się z nami, jeśli poszukujesz ekspertów, gotowych dostarczyć kompleksowe rozwiązania dopasowane do Twoich potrzeb.
          </p>
        </BluePart>
        <ImagePart image={image5} />
      </Block>
    </>

  );
}

export default Contact;