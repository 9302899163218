import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-abak.png';
import iconAddr from '../../assets/icons/icon-addr.svg';
import iconPhone from '../../assets/icons/icon-phone.svg';
import iconEmail from '../../assets/icons/icon-email.svg';

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: #199CB5;
  margin-top: 100px;
  height: 300px;
  width: 100vw;
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
    padding: 20px 0 64px 0;
    gap: 64px;
  }
  //box-shadow: 0px 0px 20px 1px #000000;
`;

const FooterSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShortcutsSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 30px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLink = styled(Link)`
  color: #199CB5;
  text-decoration: none;
  padding: 7px 0;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: normal;
  margin: 10px 0;
`

const Content = styled.p`
  margin: 0;
  font-size: 16px
`
const ShortcutsTitle = styled.h3`
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 20px 0;
`


const AppFooter = () => {
  return (
    <Footer>
      <FooterSection>
        <img src={logo} alt="Logo" style={{ height: 58 }} />
      </FooterSection>
      <FooterSection>
        <FooterInfo>
          <img src={iconAddr} alt="Icon" />
          <Title>Adres:</Title>
          <Content>ABAK SYSTEM SP. Z O.O.</Content>
          <Content>Starorudzka 10/12</Content>
          <Content>93-418 Łódź</Content>
          <Content>NIP: 729 268 95 02</Content>
        </FooterInfo>
      </FooterSection>
      <FooterSection>
        <FooterInfo>
          <img src={iconPhone} alt="Icon" />
          <Title>Telefon:</Title>
          <Content>(42) 640 20 50</Content>
          <Content>601 25 70 80</Content>
        </FooterInfo>
      </FooterSection>
      <FooterSection>
        <FooterInfo>
          <img src={iconEmail} alt="Icon" />
          <Title>E-mail:</Title>
          <Content>abak@post.pl</Content>
        </FooterInfo>
      </FooterSection>
      <ShortcutsSection>
        <ShortcutsTitle>NA SKRÓTY</ShortcutsTitle>
        <FooterLink to="/about-us">O FIRMIE</FooterLink>
        <FooterLink to="/offer">OFERTA</FooterLink>
        <FooterLink to="/our-projects">NASZE REALIZACJE</FooterLink>
        <FooterLink to="/references">REFERENCJE</FooterLink>
        <FooterLink to="/contact">KONTAKT</FooterLink>
      </ShortcutsSection>
    </Footer>
  );
};

export default AppFooter;