import styled from '@emotion/styled';
import backgroundImage from '../assets/references-cut.png';
import PDFGrid from "../components/PDFGrid";

const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  height: 480px;
  width: 100vw;
  position: relative;
  display: flex;
  margin-top: -112px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(30.1deg, rgba(0, 0, 0, 0.6) 12.8%, rgba(0, 0, 0, 0.48) 27.62%, rgba(0, 0, 0, 0) 85.09%);
  z-index: 1;
`;

const Text = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 135px;
  left: 40px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 60px;
    left: 10px;
  }

  @media (max-width: 400px) {
    font-size: 36px;
    bottom: 50px;
    left: 10px;
  }
`;

const Text2 = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 60px;
  left: 40px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 0px;
    left: 10px;
  }

  @media (max-width: 400px) {
    font-size: 36px;
  }
`;

const Separator = styled.div`
  height: 96px;
  background: linear-gradient(180deg, #000000 0%, rgba(21, 24, 27, 0) 31.82%);
`;

const HighlightedText = styled.span`
  color: #9ECFD9;
`;

const References = () => {

  const pdfs = Array.from({ length: 16 }, (v, i) => {
    return {
      id: i + 1,
      thumbnail: require(`../assets/PDFs/thumbnails/${i + 1}.png`),
      url: require(`../assets/PDFs/docs/${i + 1}.pdf`)
    };
  });

  return (
    <>
      <Background>
        <Gradient/>
        <Text>
          ABAK <HighlightedText>SYSTEM</HighlightedText>
        </Text>
        <Text2>
          RZETELNOŚĆ I <HighlightedText>ZAUFANIE</HighlightedText>
        </Text2>
      </Background>
      <Separator/>
      <PDFGrid pdfs={pdfs} />
    </>

  );
}

export default References;