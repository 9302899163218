import React, {useState} from 'react';
import styled from "@emotion/styled";


const ListItem = styled.li`
  line-height: 25px;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  height: auto;
  //width: 100vw;
  width: 400px;
  min-width: 400px;
  margin-bottom: 42px;
  box-shadow: 0px -9px 4px rgba(0, 0, 0, 0.23), 0px 9px 4px rgba(0, 0, 0, 0.23);

  &:hover {
    box-shadow: 0px 0px 24px 0px #126C7D;
  }
`

const BluePart = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  background: linear-gradient(261.59deg, rgba(25, 156, 181, 0.7) 0.78%, #2FD3F3 54.91%, rgba(25, 156, 181, 0.3) 98.71%),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  padding: 36px;
  //justify-content: center;
  height: 550px;
  //height: 100%;
`
const BlackPart = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  padding: 42px;
  justify-content: center;
  //align-items: center;
  text-align: center;
  height: 250px;
`

const ImagePart = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  height: 300px
`

export const BlackPartTitle = styled.div`
  text-transform: uppercase;
  font-size: 20px;
`

const BluePartTitle = styled.div`
  text-transform: uppercase;
  font-size: 20px;
`

const Button = styled.div`
  //background: linear-gradient(261.59deg, rgba(25, 156, 181, 0.7) 0.78%, #2FD3F3 54.91%, rgba(25, 156, 181, 0.3) 98.71%),
  //linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  color: #199BC5;
  //color: black;
  height: 44px;
  line-height: 40px;
  width: 150px;
  border-radius: 7px;
  border: 1px solid #199CB5;
  margin-top: 20px
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: center;
`;


const ProjectsDesktop = ({contents}) => {

  const [isOpen, setIsOpen] = useState(Array(contents.length).fill(false));
  const [isHovered, setIsHovered] = useState(Array(contents.length).fill(false));

  return <Row>
    {contents.map((content, index) => (
        <Block
          onMouseEnter={() => setIsHovered(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          })}
          onMouseLeave={() => setIsHovered(prevState => {
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          })}
          key={index}
        >
          {!isHovered[index] ? <ImagePart image={content.image}/> : null}
          {isHovered[index] && <BluePart>
            <BluePartTitle>
              {content.title}
            </BluePartTitle>
            <p>
              {content.content}
            </p>
            {content.pointers ?
              <ul>
                {content.pointers.map((pointer, index) => (
                  <ListItem key={index}>
                    {pointer}
                  </ListItem>
                ))}
              </ul> : null}
          </BluePart>}
          {!isHovered[index] && <BlackPart>
            <BlackPartTitle>
              {content.title}
            </BlackPartTitle>
            {/*<Button*/}
            {/*  onClick={() => setIsOpen(prevState => {*/}
            {/*    const newState = [...prevState];*/}
            {/*    newState[index] = true;*/}
            {/*    return newState;*/}
            {/*  })}*/}
            {/*>*/}
            {/*  Więcej*/}
            {/*</Button>*/}
          </BlackPart>}
        </Block>
      ))
    }
  </Row>

}

export default ProjectsDesktop;