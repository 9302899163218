import { injectGlobal } from "@emotion/css";

export default function createGlobalStyle() {
  injectGlobal`
    body {
      line-height: normal;
      background: linear-gradient(267.26deg, #16191C 31.64%, #27292E 95.9%);
      height: 100vh;
      margin: 0;
      background-repeat: no-repeat;
      background-attachment: fixed;
      overflow-x: hidden;
    }
    @font-face {
      font-family: 'Impact';
      src: url('./assets/fonts/impact.ttf') format('truetype');
    }

    .swiper-pagination-bullet {
      background-color: #199CB5;
      width: 8px;
      height: 8px;
    }

    .swiper-pagination-bullet-active {
      background-color: #199CB5;
      width: 8px;
      height: 8px;
      box-shadow: 0px 0px 8px 2.5px #199CB5;
    }
    .swiper-3d .swiper-slide-shadow {
      display: none !important;
    }
    
:root {

/* fonts */
--bold-26: Roboto;
--font-impact: Impact;

/* font sizes */
--regular-14-size: 14px;
--font-size-xl: 20px;
--regular-16-size: 16px;
--font-size-36xl: 55px;
--font-size-14xl: 33px;
--font-size-25xl: 44px;
--font-size-20xl: 39px;
--font-size-4xl: 23px;
--font-size-12xl: 31px;
--regular-18-size: 18px;
--font-size-28xl: 47px;
--font-size-9xl: 28px;
--font-size-19xl: 38px;
--font-size-43xl: 62px;
--font-size-18xl: 37px;
--medium-50-size: 50px;
--bold-26-size: 26px;
--medium-12-size: 12px;
--medium-36-size: 36px;

/* Colors */
--color-cadetblue: #199cb5;
--color-gray-100: #171a1d;
--colour-main-blue-900: #091133;
--color-lightblue: #9ecfd9;
--colour-secondary-white-100-general: #fff;
--colour-logo-primary: #37447e;
--colour-main-blue-700: #222f65;
--colour-main-blue-800: #111b47;
--color-lightgray: #cdd1d4;
--colour-main-blue-300: #929ecc;
--colour-main-grey-400: #939ea4;
--color-lavender: #e7ecff;
--colour-main-blue-400: #6f7cb2;
--colour-main-blue-500: #505f98;
--colour-main-grey-600: #5d6970;

/* Gaps */
--gap-xl: 20px;
--gap-16xl: 35px;
--gap-mid: 17px;
--gap-2xl: 21px;
--gap-sm: 14px;
--gap-55xl: 74px;
--gap-18xl: 37px;
--gap-lg: 18px;
--gap-30xl: 49px;
--gap-5xl: 24px;
--gap-9xl: 28px;
--gap-64xl: 83px;
--gap-6xs: 7px;
--gap-490xl: 509px;
--gap-108xl: 127px;
--gap-235xl: 254px;
--gap-2xs: 11px;

/* Paddings */
--padding-smi: 13px;
--padding-58xl: 77px;
--padding-23xl: 42px;
--padding-19xl: 38px;
--padding-mini: 15px;
--padding-6xs: 7px;
--padding-72xl-5: 91.5px;
--padding-xl: 20px;
--padding-lg: 18px;
--padding-55xl: 74px;
--padding-2xl: 21px;
--padding-29xl: 48px;
--padding-4xs: 9px;
--padding-151xl: 170px;
--padding-91xl: 110px;
--padding-12xs: 1px;
--padding-2xs: 11px;
--padding-8xs: 5px;
--padding-25xl: 44px;
--padding-56xl: 75px;
--padding-8xl: 27px;
--padding-37xl: 56px;
--padding-4xl: 23px;
--padding-17xl: 36px;
--padding-48xl: 67px;
--padding-10xl: 29px;
--padding-13xl: 32px;
--padding-488xl: 507px;
--padding-107xl: 126px;
--padding-234xl: 253px;
--padding-sm-5: 13.5px;

/* Border radiuses */
--br-xl: 20px;
--br-31xl: 50px;
--br-6xs: 7px;
--br-smi: 13px;
--br-11xs: 2px;

}
`;
}
