import styled from '@emotion/styled';
import backgroundImage from '../assets/offersBackground.png';
import toolIcon from "../assets/icons/tool-icon.svg";
import pencilIcon from "../assets/icons/pencil-icon.svg";
import boltIcon from "../assets/icons/bolt-icon.svg";
import plugIcon from "../assets/icons/plug-icon.svg";
import sunIcon from "../assets/icons/sun-icon.svg";
import React from "react";

const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  height: 480px;
  width: 100vw;
  position: relative;
  display: flex;
  margin-top: -112px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(30.1deg, rgba(0, 0, 0, 0.6) 12.8%, rgba(0, 0, 0, 0.48) 27.62%, rgba(0, 0, 0, 0) 85.09%);
  z-index: 1;
`;

const Text = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 135px;
  left: 40px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 120px;
    left: 10px;
  }

  @media (max-width: 360px) {
    font-size: 36px;
    bottom: 105px;
    left: 10px;
  }
`;

const Text2 = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 60px;
  left: 40px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 0px;
    left: 10px;
  }

  @media (max-width: 360px) {
    font-size: 36px;
  }
`;

const Separator = styled.div`
  height: 96px;
  background: linear-gradient(180deg, #000000 0%, rgba(21, 24, 27, 0) 31.82%);
`;

const HighlightedText = styled.span`
  color: #9ECFD9;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 50px;
  padding: 42px;
`;

const GridItem = styled.div`
  position: relative;
  height: 830px;
  width: 400px;
  
  @media (max-width: 600px) {
    height: auto;
  }
`;

const Part = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  color: #199CB5;
  padding: 20px;
  border-radius: 7px;
  border: 2px solid #199CB5;
  background: linear-gradient(267.26deg, #16191C 31.64%, #27292E 95.9%);
  height: 100%;
  width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const DescriptionList = styled.ul`
  font-size: 16px;
`;

const DescriptionListItem = styled.li`
  font-size: 16px;
  list-style-type: none;
  display: flex; /* Add this line */
  align-items: flex-start; /* Add this line */
  line-height: 35px;
  width: 100%;

  ::before {
    content: "";  /* This is your custom bullet point */
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 20px;
    margin-top: 15px;
    border-radius: 50%; /* Make the bullet point circular */
    background-color: #199CB5;  /* Change the color to your desired color */
    box-shadow: 0px 0px 8px 2.5px #199CB5;  /* Add a shadow to the bullet point */
  }
`;


const Offers = () => {

  const offers = [
    {
      title: 'Instalacje elektryczne',
      icon: boltIcon,
      items: [
        "Montaż stacji transformatorowych",
        "Prefabrykacja rozdzielnic niskiego napięcia",
        "Instalacje tras kablowych",
        "Wewnętrzne linie zasilające",
        "Instalacja siłowa i gniazd wtykowych",
        "Instalacje oświetlenia podstawowego",
        "Instalacje oświetlenia awaryjnego i ewakuacyjnego",
        "Instalacje połączeń wyrównawczych",
        "Instalacje odgromowe",
        "Instalacje oświetlenia drogowego",
        "Pomiary elektryczne",
        "Instalacje oświetlenia przeszkodowego",
        "Zasilanie placów budowy",
        "Instalacja ładowarek samochodowych"
      ]
    },
    {
      title: 'Instalacje teletechiczne',
      icon: plugIcon,
      items: [
        "Systemy alarmowe i kontroli dostępu SSWiN oraz K/D",
        "Sieci komputerowych LAN",
        "Systemy nagłośnienia i ewakuacji DSO",
        "Systemy sygnalizacji i wykrywania pożaru SAP",
        "Systemy oddymiania",
        "Systemy telewizji przemysłowej CCTV",
        "Systemy automatyki, sterowania i kontrolowania BMS",
        "Systemy inteligentnych EIB"
      ]
    },
    {
      title: 'Instalacje fotowoltaiczne',
      icon: toolIcon,
      items: [
        "Montaż paneli fotowoltaicznych",
        "Montaż instalacji DC",
        "Montaż instalacji AC",
        "Montaż inwerterów",
        "Obsługa w zakresie procesowania dokumentacji"
      ]
    },
    {
      title: 'Projektowanie',
      icon: pencilIcon,
      items: [
        "Projekty instalacji niskiego napięcia",
        "Projekty instalacji średniego napięcia",
        "Projekty instalacji oświetlenia podstawowego",
        "Projekty instalacji oświetlenia awaryjnego i ewakuacyjnego",
        "Projekty instalacji teletechnicznych",
        "Projekty stacji transformatorowych"
      ]
    },
    {
      title: 'Obsługa serwisowa',
      icon: toolIcon,
      items: [
        "Przeglądy instalacji SSP",
        "Przeglądy instalacji elektrycznych",
        "Przeglądy instalacji oświetleniowych"
      ]
    },
  ]

  return (
    <>
      <Background>
        <Gradient/>
        <Text>
          ABAK <HighlightedText>SYSTEM</HighlightedText>
        </Text>
        <Text2>
          NASZA <HighlightedText>OFERTA</HighlightedText> TWOJE <HighlightedText>KORZYŚCI</HighlightedText>
        </Text2>
      </Background>
      <Separator/>
      <Grid>
        {offers.map((offer) =>
          <GridItem>
            <Part>
              <Title>{offer.title}</Title>
              <Logo src={offer.icon} alt={offer.title}/>
              <DescriptionList>
                {
                  offer.items.map((item) =>
                  <DescriptionListItem>{item}</DescriptionListItem>
                )}
              </DescriptionList>
            </Part>
          </GridItem>
        )}
      </Grid>

    </>

  );
}

export default Offers;