import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Autoplay, Pagination} from "swiper/modules";
import styled from "@emotion/styled";
import React from "react";
import toolIcon from "../../assets/icons/tool-icon.svg";
import pencilIcon from "../../assets/icons/pencil-icon.svg";
import boltIcon from "../../assets/icons/bolt-icon.svg";
import plugIcon from "../../assets/icons/plug-icon.svg";
import sunIcon from "../../assets/icons/sun-icon.svg";
import { EffectCards } from 'swiper/modules';

const Part = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #199CB5;
  padding: 20px;
  border-radius: 7px;
  border: 2px solid #199CB5;
  height: 350px;
  margin-bottom: 50px;
  background: linear-gradient(267.26deg, #16191C 31.64%, #27292E 95.9%);
  width: 50%;
  margin-left: 20vw;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
`;


const MobileOffers = () => {

  return (
    <Swiper
      style={{margin: '0 20px'}}
      spaceBetween={20}
      pagination={{clickable: true}}
      modules={[Pagination, Autoplay, EffectCards]}
      effect={'cards'}
      grabCursor={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: true,
      }}
      // breakpoints={{
      //   200: {
      //     slidesPerView: 1,
      //     slidesPerGroup: 1,
      //   },
      //   640: {
      //     slidesPerView: 2,
      //     slidesPerGroup: 2,
      //   },
      //   768: {
      //     slidesPerView: 3,
      //     slidesPerGroup: 3,
      //   },
      //   1024: {
      //     slidesPerView: 3,
      //     slidesPerGroup: 3,
      //   },
      //   1300: {
      //     slidesPerView: 3,
      //     slidesPerGroup: 3,
      //   },
      // }}
    >
      <SwiperSlide>
        <Part>
          <Title>Obsługa serwisowa</Title>
          <Logo src={toolIcon} alt="Logo 1"/>
          <Description>Zajmujemy się kompleksowymi przeglądami różnego rodzaju instalacji.</Description>
        </Part>
      </SwiperSlide>
      <SwiperSlide>
        <Part>
          <Title>Projektowanie</Title>
          <Logo src={pencilIcon} alt="Logo 2"/>
          <Description>Oferujemy kompleksowe projekty instalacji elektrycznych różnego typu.</Description>
        </Part>
      </SwiperSlide>
      <SwiperSlide>
        <Part>
          <Title>Instalacje elektryczne</Title>
          <Logo src={boltIcon} alt="Logo 3"/>
          <Description>Zapewniamy szeroki zakres usług związanych z instalacjami elektrycznymi.</Description>
        </Part>
      </SwiperSlide>
      <SwiperSlide>
        <Part>
          <Title>Instalacje teletechiczne</Title>
          <Logo src={plugIcon} alt="Logo 4"/>
          <Description>Oferujemy kompleksowe rozwiązania w zakresie różnorodnych systemów bezpieczeństwa oraz
            dźwiękowych.</Description>
        </Part>
      </SwiperSlide>
      <SwiperSlide>
        <Part>
          <Title>Instalacje fotowoltaiczne</Title>
          <Logo src={sunIcon} alt="Logo 5"/>
          <Description>Oferujemy kompleksowe usługi związane z montażem oraz obsługą paneli fotowoltaicznych i
            instalacji elektrycznych.</Description>
        </Part>
      </SwiperSlide>
    </Swiper>
  );
};

export default MobileOffers;