import React from 'react';
import styled from '@emotion/styled';
import boltIcon from '../../assets/icons/bolt-icon.svg';
import pencilIcon from '../../assets/icons/pencil-icon.svg';
import sunIcon from '../../assets/icons/sun-icon.svg';
import toolIcon from '../../assets/icons/tool-icon.svg';
import plugIcon from '../../assets/icons/plug-icon.svg';
import cable from '../../assets/cable.svg';
import {useMediaQuery} from "@mui/material";
import MobileOffers from "./mobile";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-image: url(${cable});
  background-size: 97% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
  padding: 0 20px;
  
  @media (max-width: 1150px) {
    height: 600px;
  }

  @media (min-width: 1800px) {
    background-size: 98% 100%;
    height: 600px;
  }
  
  @media (max-width: 900px) {
    background: none;
  }
`;

const Part = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #199CB5;
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
`;

const Offer = () => {

  const isMobile = useMediaQuery('(max-width: 900px)');

  if(isMobile) {
    return <MobileOffers />;
  }

  return (
    <Container>
      <Part>
        <Title>Obsługa serwisowa</Title>
        <Logo src={toolIcon} alt="Logo 1" />
        <Description>Zajmujemy się kompleksowymi przeglądami różnego rodzaju instalacji.</Description>
      </Part>
      <Part>
        <Title>Projektowanie</Title>
        <Logo src={pencilIcon} alt="Logo 2" />
        <Description>Oferujemy kompleksowe projekty instalacji elektrycznych różnego typu.</Description>
      </Part>
      <Part>
        <Title>Instalacje elektryczne</Title>
        <Logo src={boltIcon} alt="Logo 3" />
        <Description>Zapewniamy szeroki zakres usług związanych z instalacjami elektrycznymi.</Description>
      </Part>
      <Part>
        <Title>Instalacje teletechniczne</Title>
        <Logo src={plugIcon} alt="Logo 4" />
        <Description>Oferujemy kompleksowe rozwiązania w zakresie różnorodnych systemów bezpieczeństwa oraz dźwiękowych.</Description>
      </Part>
      <Part>
        <Title>Instalacje fotowoltaiczne</Title>
        <Logo src={sunIcon} alt="Logo 5" />
        <Description>Oferujemy kompleksowe usługi związane z montażem oraz obsługą paneli fotowoltaicznych i instalacji elektrycznych.</Description>
      </Part>
    </Container>
  );
};

export default Offer;