import React from 'react';
import companyImage from '../../assets/company-smaller.png';
import styled from '@emotion/styled';

const OuterDiv = styled.div`
  margin-top: 100px;
  position: relative;
  height: 570px;
  @media (max-width: 600px) {
    margin-bottom: 450px;
    display: flex;
    flex-direction: column;
  }
`;

const InnerDiv1 = styled.div`
  width: 65vw;
  background: linear-gradient(180deg, #11778A 0%, #199CB5 100%);
  border-radius: 0px 7px 7px 0px;
  padding: 38px 42px 38px 42px;
  
  @media (max-width: 601px) {
    width: 100vw;
    border-radius: 7px 7px 0px 0px;
  }
`;

const InnerDiv2 = styled.div`
  width: 55vw;
  @media (max-width: 601px) {
    width: 100%;
  }
`;


const OverlayImage = styled.img`
  position: absolute;
  top: 100px; // adjust this value as needed
  right: 0; // adjust this value as needed
  height: 420px;
  width: 40vw;
  object-fit: cover;
  border-radius: 7px 0 0 7px;
  min-width: 420px;
  
  @media (max-width: 600px) {
    position: static;
    width: 100%;
    height: auto;
    min-width: 0;
    border-radius: 0px 0px 7px 7px;
  }
`;

const Index = () => {
  return (
    <OuterDiv>
      <InnerDiv1>
        <InnerDiv2>
          <h1>O FIRMIE</h1>
          <p>
            Firma Abak System Sp. z o.o. jest przedsiębiorstwem z sektora budowlanego, która od ponad 20 lat zajmuje się
            wykonawstwem instalacji elektrycznych, teletechnicznych oraz fotowoltaicznych.
          </p>
          <p>
            W swojej ofercie świadczy również usługi związane z projektowaniem instalacji elektrycznych oraz z obsługą
            serwisową. Taki szereg usług sprawia, że firma Abak System jest w stanie zapewnić kompleksową usługę,
            rozpoczynając od stworzenia projektu, poprzez wykonawstwo instalacji aż po usługi serwisowe.
          </p>
        </InnerDiv2>
      </InnerDiv1>
      <OverlayImage src={companyImage} alt="company" />
    </OuterDiv>
  );
};

export default Index;