import styled from '@emotion/styled';
import backgroundImage from '../assets/projectsBackground.png';
import {Link} from 'react-scroll';
import downArrowIcon from '../assets/icons/icon-arrow-down.svg';
import React, {useState} from "react";
import {useMediaQuery} from "@mui/material";
import ProjectsMobile from "../components/Projects/mobile";
import ProjectsDesktop from "../components/Projects/desktop";

const Background = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  margin-top: -112px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(269.9deg, rgba(0, 0, 0, 0) 0.06%, rgba(0, 0, 0, 0.8) 68.94%);
  z-index: 1;
  //@media (max-width: 600px) {
  //  background: linear-gradient(269.9deg, rgba(0, 0, 0, 0) 0.06%, rgba(0, 0, 0, 0.8) 0%);
  //}
`;

const Text = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 335px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 115px;
    left: 10px;
  }

  @media (max-width: 360px) {
    font-size: 36px;
    bottom: 110px;
    left: 10px;
  }
`;

const Text2 = styled.div`
  font-size: 62px;
  font-family: 'Impact', roboto;
  position: absolute;
  bottom: 250px;
  left: 100px;
  color: #199CB5;
  //padding: 10px;
  z-index: 2;
  
  @media (max-width: 600px) {
    font-size: 40px;
    bottom: 60px;
    left: 10px;
  }
  
  @media (max-width: 360px) {
    font-size: 36px;
  }
`;

const Separator = styled.div`
  height: 96px;
  background: linear-gradient(180deg, #000000 0%, rgba(21, 24, 27, 0) 31.82%);
`;

const HighlightedText = styled.span`
  color: #9ECFD9;
`;

const SectionTitle = styled.div`
  font-family: roboto;
  font-weight: 700;
  font-size: 32px;
  color: #199CB5;
  text-transform: uppercase;
  padding-left: 27px;
  margin-bottom: 50px;
  
  @media (max-width: 600px) {
    padding-left: 27px;
    padding-right: 27px;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  height: 400px;
  width: 100vw;
  margin-bottom: 42px;
  box-shadow: 0px -9px 4px rgba(0, 0, 0, 0.23), 0px 9px 4px rgba(0, 0, 0, 0.23);

  &:hover {
    box-shadow: 0px 0px 24px 0px #126C7D;
  }
`

const BluePart = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  background: linear-gradient(261.59deg, rgba(25, 156, 181, 0.7) 0.78%, #2FD3F3 54.91%, rgba(25, 156, 181, 0.3) 98.71%),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  padding: 42px;
  justify-content: flex-start;
  width: 40%;
  justify-content: center;
  //align-items: center;
  //text-align: center;
`
const BlackPart = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  //background: linear-gradient(180deg, #11778A 0%, #199CB5 100%);
  padding: 42px;
  width: 40%;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const ImagePart = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  width: 60%;
`

export const BlackPartTitle = styled.div`
  text-transform: uppercase;
  font-size: 30px;
`

const BluePartTitle = styled.div`
  text-transform: uppercase;
  font-size: 20px;
`

const ArrowContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 49%;
  z-index: 10;
  background: transparent;

  @media (max-width: 600px) {
    right: 45%;
  }
`

const ListItem = styled.li`
  line-height: 40px;
`


const Projects = () => {

  const contents = [
    {
      title: "hampton by hilton",
      pointers: [
        "Montaż szynoprzewodu",
        "Montaż tras kablowych elektrycznych i teletechnicznych",
        "Instalacje siły i gniazd wtykowych",
        "Instalacje oświetlenia podstawowego wewnętrznego",
        "Instalacje oświetlenia awaryjnego i ewakuacyjnego",
        "Instalacja okablowania strukturalnego – okablowanie miedziane oraz światłowodowe",
        "Instalacja szaf dystrybucyjnych głównych i piętrowych",
        "Instalacja odgromowa",
        "Pomiary elektryczne",
        "Pomiary oświetlenia"
      ],
      image_id: 14,
      image_dir: "jobs"
    },
    {
      title: "hotel ambasador",
      pointers: [
        "Instalacja uziemień i połączeń wyrównawczych",
        "Instalacja zasilania placu budowy",
        "Instalacja zasilania technologii kabli grzewczych na cele realizacji prac żelbetowych w okresie zimowym"
      ],
      image_id: 15,
      image_dir: "jobs"
    },
    {
      title: "pałac Steinertów",
      content: "Prace elektryczne na obiekcie objętym opieką konserwatora zabytków:",
      pointers: [
        "Instalacje oświetlenia wewnętrznego",
        "Instalacja oświetlenia terenu",
        "Instalacja tras kablowych",
        "Instalacja uziemiająca i wyrównawcza",
        "Instalacja kabli grzewczych",
        "Instalacja rozdzielnic głównych",
        "Instalacja odgromowa",
        "Montaż dekoracyjnych opraw oświetleniowych"
      ],
      image_id: 20,
      image_dir: "jobs"
    },
    {
      title: "Centrum badawczo-rozwojowe agros nova",
      // content: "Montaż rozdzielnic, tras kablowych, WLZ Montaż oświetlenia podstawowego, zewnętrznego i awaryjnego oraz urządzeń technologicznych Montaż instalacji odgromowej i wyrównania potencjału Instalacje oświetlenia wewnętrznego i zewnętrznego",
      pointers: [
        "Montaż rozdzielnic",
        "Tras kablowych WLZ",
        "Montaż oświetlenia podstawowego, zewnętrznego i awaryjnego oraz urządzeń technologicznych",
        "Montaż instalacji odgromowej i wyrównania potencjału",
        "Instalacje oświetlenia wewnętrznego i zewnętrznego"
      ],
      image_id: 1,
      image_dir: "projects"
    },
    {
      title: "PORT LOTNICZY ŁÓDŹ im. Władysława Reymonta ",
      content: "Wykonanie instalacji elektrycznych i teletechnicznych:",
      pointers: [
        "oświetlenia, gniazd wtykowych, odbiorników siłowych, uziemiająca i wyrównawcza, odgromowa",
        "rozdzielnica główna i tablice zasilające",
        "sieć strukturalna + UPS System",
        "system Sygnalizacji Pożaru SAP",
        "system Monitoringu CCTV",
        "system Sygnalizacji Alarmu i Włamania",
        "system Kontroli Dostęp",
        "system przyzywowy, domofony"
      ],
      image_id: 2,
      image_dir: "projects"
    },
    {
      title: "FABRYKA SZTUKI - ART INKUBATOR ",
      // content: "System informacyjny i struktura teleinformacyjna System Monitoringu CCTV Instalacja ppoż i oddymiania",
      pointers:[
        "System informacyjny i struktura teleinformacyjna",
        "System Monitoringu CCTV",
        "Instalacja ppoż i oddymiania"
      ],
      image_id: 3,
      image_dir: "projects"
    },
    {
      title: "CH MANUFAKTURA (Łódź)",
      // content: 'Wykonanie systemu sygnalizacji pożaru – SAP (SCHRACK) oraz DSO.',
      pointers: ["Wykonanie systemu sygnalizacji pożaru – SAP (SCHRACK) oraz DSO"],
      image_id: 4,
      image_dir: "jobs"
    },
    {
      title: "FABRYKA BOSCH (Łódź)",
      // content: 'Wykonanie systemu kamer telewizji przemysłowej CCTV i instalacji elektrycznej.',
      pointers: ["Wykonanie systemu kamer telewizji przemysłowej CCTV i instalacji elektrycznych "],
      image_id: 8,
      image_dir: "jobs"
    },
    {
      title: "IKEA (Łódź)",
      // content: 'Wykonanie systemu alarmowego (GALAXY) i instalacji elektrycznej średniego i niskiego napięcia.',
      pointers: [
        "Wykonanie systemu alarmowego (GALAXY)",
        "Wykonanie instalacji elektrycznej średniego i niskiego napięcia"
      ],
      image_id: 16,
      image_dir: "jobs"
    },
    {
      title: "TUNEL Rynek Kobro",
      // content: 'Projekt i realizacja podziemnej stacji transformatorowej wraz z dwoma przyłączami kablowymi 15kV',
      pointers: [
        "Projekt i realizacja podziemnej stacji transformatorowej wraz z dwoma przyłączami kablowymi 15kV"
      ],
      image_id: 23,
      image_dir: "jobs"
    },
    {
      title: "Barry Callebaut (Łódź)",
      pointers: [
        "Montaż słupów oświetleniowych",
        "Rozbudowa rozdzielnicy SN w stacji transformatorowej",
        "Instalacja linii kablowej 15kV ze stacji transformatorowej",
        "Instalacja oświetlenia",
        "Zasilanie urządzeń wentylacji",
        "Instalacja odgromowa i uziemiająca",
        "Montaż i instalacja stacji transformatorowej"
      ],
      image_id: 9,
      image_dir: "jobs"
    },
    {
      title: "Ogród Zoologiczny „Orientarium” Łódź",
      pointers: [
        "Montaż rozdzielnic nn i SN",
        "Montaż tras kablowych nn i SN",
        "Montaż kabli zasilających SN",
        "Montaż WLZ i szynoprzewodów SN",
        "Montaż zasilania urządzeń technologicznych",
        "Montaż oświetlenia zewnętrznego"
      ],
      image_id: 18,
      image_dir: "jobs"
    },
    {
      title: "CTI Politechnika Łodzka",
      pointers: [
        "Instalacje LAN i niskoprądowe",
        "Instalacje SAP i oddymiania",
        "Przyłącza teletechniczne"
      ],
      image_id: 11,
      image_dir: "jobs"
    },
    {
      title: "UMED –Zakład Farmakologii Toksykologii Uniwersytetu Medycznego",
      pointers: [
        "Instalacja oświetlenia wewnętrznego oraz odgromowego",
        "Montaż/Instalacja tras kablowych oraz opraw oświetleniowych",
      ],
      image_id: 25,
      image_dir: "jobs"
    },
    {
      title: "Geberit",
      content: "Projekt modernizacji i rozbudowy Systemu Sygnalizacji Pożaru ESSER w obszarach produkcyjnych, magazynowych oraz biurowych.",
      pointers: [
        "Wykonanie Instalacji SSP ",
        "Instalacja systemu zasysającego VESDA",
        "Instalacja detektorów płomieni",
        "Integracja systemu SSP z urządzeniami technologii produkcji"
      ],
      image_id: 12,
      image_dir: "jobs"
    },
    {
      title: "Synergia",
      pointers: [
        "Instalacja oświetlenia wewnętrznego oraz odgromowego",
        "Montaż/Instalacja tras kablowych oraz opraw oświetleniowych",
      ],
      image_id: 30,
      image_dir: "jobs"
    },
    {
      title: "Hotel puro",
      pointers: [
        "Montaż tras kablowych",
        "Montaż szynoprzewodu",
        "Instalacje siły i gniazd wtykowych",
        "Instalacje oświetlenia podstawowego wewnętrznego",
        "Instalacje oświetlenia awaryjnego i ewakuacyjnego",
        "Instalacja oświetlenia iluminacji budynku",
        "Instalacja odgromowa",
        "Pomiary elektryczne",
        "Pomiary oświetlenia"
      ],
      image_id: 28,
      image_dir: "jobs"
    },
    {
      title: "Uniwersytecki Szpital Kliniczny im. WAM w Łodzi – Maszty Oświetleniowe",
      pointers: [
        "Renowacja słupów oświetleniowych 10m",
        "Wymiana okablowania i zabezpieczeń elektrycznych słupów oświetleniowych",
        "Czyszczenie, wymiana kloszy opraw oświetleniowych, źródeł światła",
        "Czyszczenie i malowanie słupów oświetleniowych",
        "Organizacja zabezpieczeń obszaru prac",
        "Ustalenia dot. zmiany organizacji ruchu oraz zajętości pasa ruchu",
        "Pomiary oświetlenia"
      ],
      image_id: 31,
      image_dir: "jobs"
    },
    {
      title: "basen anilana",
      pointers: [
        "Montaż kanalizacji kablowej",
        "Montaż słupów oświetlenia w obszarze parkingu oraz strefy basenowej",
        "Instalacje oświetlenia, siły i gniazd wtykowych w pomieszczeniu technologii basenu",
        "Instalacja UPS",
        "Instalacja połączeń ekwipotencjalnych dla zbiornika basenu",
        "Instalacja baterii kondensatorów do kompensacji mocy biernej",
        "Pomiary elektryczne",
        "Pomiary oświetlenia"
      ],
      image_id: 5,
      image_dir: "jobs"
    },
    {
      title: "BSH ŁÓDŹ",
      pointers: [
        "Montaż tras kablowych",
        "Instalacje siły i gniazd wtykowych",
        "Instalacje oświetlenia podstawowego wewnętrznego",
        "Instalacje oświetlenia awaryjnego i ewakuacyjnego w systemie CEAG",
        "Instalacja sieci LAN",
        "Instalacja odgromowa",
        "Pomiary elektryczne",
        "Pomiary oświetlenia"
      ],
      image_id: 26,
      image_dir: "jobs"
    },
    {
      title: "DWORZEC ŁÓDŹ FABRYCZNA",
      pointers: [
        "Montaż tras kablowych",
        "Instalacje siły i gniazd wtykowych",
        "Instalacje oświetlenia podstawowego wewnętrznego",
        "Instalacje oświetlenia awaryjnego i ewakuacyjnego",
        "Instalacja zasilania wentylacji",
        "Instalacja kabli sterowniczych",
        "Pomiary elektryczne",
        "Pomiary oświetlenia",
        "Wykonanie zabezpieczeń pożarowych przepustów kablowych do EI240"
      ],
      image_id: 27,
      image_dir: "jobs"
    },
    {
      title: "Novartis",
      pointers: [
        "Modernizacja oświetlenia podstawowego na obszarze produkcyjnym",
        "Instalacja oświetlenia awaryjnego i ewakuacyjnego oraz Centralnej Baterii w systemie AWEX",
        "Pomiary elektryczne",
        "Pomiary oświetlenia"
      ],
      image_id: 29,
      image_dir: "jobs"
    },
    {
      title: 'Centralny Szpital Kliniczny - CKD',
      pointers:[
        "Montaż rozdzielnic elektrycznych ogólnych",
        "Montaż rozdzielnic elektrycznych medycznych IT",
        "Montaż tras kablowych",
        "Instalacje siły i gniazd wtykowych",
        "Instalacje oświetlenia podstawowego wewnętrznego",
        "Instalacje oświetlenia awaryjnego i ewakuacyjnego",
        "Instalacja masztów odgromowych oraz kabli wysokonapięciowych HVI",
        "Pomiary elektryczne",
        "Pomiary oświetlenia",
        "Przejścia pożarowe"
      ],
      image_id: 10,
      image_dir: "jobs"
    },
    {
      title: "ec1 - Łódź",
      pointers: [
        "Montaż tras kablowych",
        "Instalacje siły i gniazd wtykowych",
        "Instalacje oświetlenia podstawowego wewnętrznego",
        "Instalacje oświetlenia awaryjnego i ewakuacyjnego w systemie CEAG",
        "Instalacja oświetlenia iluminacji budynku",
        "Instalacja kabli grzewczych na tarasach oraz rur spustowych",
        "Instalacja masztów odgromowych oraz kabli wysokonapięciowych HVI",
        "Pomiary elektryczne",
        "Pomiary oświetlenia",
        "Przejścia pożarowe"
      ],
      image_id: 2,
      image_dir: "jobs"
    },
    {
      title: "Łowicz",
      // content: 'Usunięcie kolizji kablowej SN',
      pointers: [
        "Usunięcie kolizji kablowej SN"
      ],
      image_id: 17,
      image_dir: "jobs"
    },
    // {
    //   title: "Henax - instalacja pv",
    //   pointers: [
    //     "Projekt oraz montaż instalacji fotowoltaicznej o mocy ………kW",
    //     "Pomiary elektryczne"
    //   ],
    //   image_id: 999,
    //   image_dir: "jobs"
    // },
  ]

  contents.forEach((content) => {
    content.image = require(`../assets/${content.image_dir}/${content.image_id}.png`);
  })


  const [isHovered, setIsHovered] = useState(Array(contents.length).fill(false));

  const isMobile = useMediaQuery('(max-width: 960px)');

  return (
    <>
      <Background>
        <Gradient/>
        <Text>
          ABAK <HighlightedText>SYSTEM</HighlightedText>
        </Text>
        <Text2>
          ZREALIZOWANE Z <HighlightedText>PASJĄ</HighlightedText>
        </Text2>
        <Link to="nextSection" smooth={true} duration={500} offset={-96}>
          <ArrowContainer>
            <img src={downArrowIcon} alt="Down arrow"/>
          </ArrowContainer>
        </Link>
      </Background>
      <Separator id="nextSection"/>
      <SectionTitle>
        Portfolio naszych sukcesów
      </SectionTitle>
      {/*{!isMobile ? contents.map((content, index) => (*/}
      {/*  <Block*/}
      {/*    key={index}*/}
      {/*    onMouseEnter={() => setIsHovered(prevState => {*/}
      {/*      const newState = [...prevState];*/}
      {/*      newState[index] = true;*/}
      {/*      return newState;*/}
      {/*    })}*/}
      {/*    onMouseLeave={() => setIsHovered(prevState => {*/}
      {/*      const newState = [...prevState];*/}
      {/*      newState[index] = false;*/}
      {/*      return newState;*/}
      {/*    })}*/}
      {/*  >*/}
      {/*    <ImagePart image={content.image}/>*/}
      {/*    {isHovered[index] && <BluePart>*/}
      {/*      <BluePartTitle>*/}
      {/*        {content.title}*/}
      {/*      </BluePartTitle>*/}
      {/*      {content.content ? <p>{content.content}</p> : null}*/}
      {/*      {content.pointers ?*/}
      {/*        <ul>*/}
      {/*          {content.pointers.map((pointer, index) => (*/}
      {/*            <ListItem key={index}>*/}
      {/*              {pointer}*/}
      {/*            </ListItem>*/}
      {/*          ))}*/}
      {/*        </ul> : null*/}
      {/*      }*/}
      {/*    </BluePart>}*/}
      {/*    {!isHovered[index] && <BlackPart>*/}
      {/*      <BlackPartTitle>*/}
      {/*        {content.title}*/}
      {/*      </BlackPartTitle>*/}
      {/*    </BlackPart>}*/}
      {/*  </Block>*/}
      {/*)) : null*/}
      {/*}*/}
      {isMobile ? <ProjectsMobile contents={contents}/> : <ProjectsDesktop contents={contents}/>}
    </>

  );
}

export default Projects;