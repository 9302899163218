import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Autoplay, Pagination} from "swiper/modules";
import styled from "@emotion/styled";
import {useState} from "react";
import {useMediaQuery} from "@mui/material";


const ImageText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 36px;
  font-weight: 500;
  color: white;
  display: flex; // add this line
  justify-content: center; // add this line
  align-items: center; // add this line
  z-index: 10;
  white-space: normal;
  text-align: center;
`;

const ImageContainer = styled.div`
  margin-bottom: 50px;
  position: relative;
  //width: 30vw;
  height: 30vh;
  border-radius: 7px;
  overflow: hidden; // to ensure the gradient stays within the image boundaries
  
  &:hover {
    cursor: pointer;
    z-index: 1;

    img {
      opacity: 0.5;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(261.59deg, rgba(25, 156, 181, 0.7) 0.78%, #2FD3F3 54.91%, rgba(25, 156, 181, 0.3) 98.71%);
    }
  }

  @media (max-width: 600px) {
    width: 99.25%;
    margin-bottom: 0;
    border-radius: 7px 7px 0 0;

    &:hover {
      img {
        opacity: 1;
      }

      &::after {
        display: none;
      }
    }
  }
`;

const Subtitle = styled.div`
  background: linear-gradient(180deg, #11778A 0%, #199CB5 100%);
  height: 70px;
  color: white;
  width: 99.25%;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  border-radius: 0 0 7px 7px;
  flex-direction: column;
  text-align: center;

  @media (max-width: 600px) {
    margin-bottom: 80px;
  }

`;

const Carousel = () => {
  const images = [
    {
      id: 1,
      title: 'Ogrodowa Office',
    },
    {
      id: 2,
      title: 'EC1 Łódź – Miasto Kultury',
    },
    {
      id: 3,
      title: 'Atlas arena',
    },
    {
      id: 4,
      title: 'Manufaktura',
    },
    {
      id: 5,
      title: 'Basen Anilana',
    },
    {
      id: 6,
      title: 'Sky Tower Wrocław',
    },
    { id: 7, title: 'Agros Nova' },
    { id: 8, title: 'BOSCH' },
    { id: 9, title: 'Barry Callebaut' },
    { id: 10, title: 'Centralny Szpital Kliniczny - CKD' },
    { id: 11, title: 'CTI Politechnika Łódzka' },
    { id: 12, title: 'Geberit' },
    { id: 13, title: 'Green Horizon' },
    { id: 14, title: 'Hampton by Hilton' },
    { id: 15, title: 'Hotel Ambasador' },
    { id: 16, title: 'IKEA' },
    { id: 18, title: 'Ogród Zoologiczny „Orientarium” Łódź' },
    { id: 19, title: 'Port Łódź' },
    { id: 20, title: 'Pałac Steinertów' },
    { id: 21, title: 'Port Lotniczy' },
    { id: 22, title: 'Sukcesja' },
    { id: 23, title: 'Tunel Rynek Kobro' },
    { id: 24, title: 'Terminał Przeładunkowy PCC Kutno' },
    { id: 25, title: 'UMED – Zakład Farmakologii i Toksykologii' },
    { id: 17, title: 'Łowicz' },
    { id: 31, title: 'Uniwersytecki Szpital Kliniczny WAM – Maszty Oświetleniowe' },
    { id: 26, title: 'BSH' },
    { id: 27, title: 'Dworzec Łódź Fabryczna' },
    { id: 29, title: 'Novartis' },
  ]

  images.forEach((image, index) => {
    image.src = require(`../../assets/jobs/${image.id}.png`);
  })

  const isMobile = useMediaQuery('(max-width: 600px)');

  const [isHovered, setIsHovered] = useState(Array(images.length).fill(false));

  return (
    <Swiper
      style={{margin: '0 20px'}}
      spaceBetween={20}
      pagination={{clickable: true}}
      modules={[Pagination, Autoplay]}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        200: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        640: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1300: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      }}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <ImageContainer
            onMouseEnter={() => setIsHovered(prevState => {
              const newState = [...prevState];
              newState[index] = true;
              return newState;
            })}
            onMouseLeave={() => setIsHovered(prevState => {
              const newState = [...prevState];
              newState[index] = false;
              return newState;
            })}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'fill',
              }}
              src={image.src}
              alt={`Slide ${index}`}
            />
            {isHovered[index] && !isMobile && <ImageText>{image.title}</ImageText>}
          </ImageContainer>
          {isMobile && <Subtitle>{image.title}</Subtitle>}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;